'use strict'

import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'

import PhoneCallRequestModal from './phone-call-request-modal'

function parseCall (body) {
  const parser = new DOMParser()

  let xmlDoc
  try {
    xmlDoc = parser.parseFromString(body.trim(), 'text/xml')
    const says = xmlDoc.getElementsByTagName('Say')
    return _.map(says, s => s.innerHTML).join(' ')
  } catch (err) {
    console.log('XML parse error', body, xmlDoc)
    return ''
  }
}

function messageTemplate (message) {
  let body = message['body']
  if (message['message_type'] === 'call') {
    body = parseCall(body)
  }

  let sender = _.get(message, 'application.name') || '(unknown)'

  return `
    <div class="message type_${message.message_type}" data-message-id="${message.id}">
      <div class="details">
        <div class="type">
          ${message['message_type_label']}
        </div>

        <div class="date">
          Sent by ${sender} on ${moment(message['created_at']).format('LLL')}
        </div>

        <div class="clearfix"></div>
      </div>

      <div class="body">
        ${_.escape(body)}
      </div>

      ${message['message_type'] === 'call' ? (`
        <div class="call-button">
          <button type="button" class="btn btn-success btn-sm" data-action="call">Call my number</button>
        </div>
      `) : ''}
    </div>
  `.trim()
}

class PhoneNumberPage {
  constructor (phoneNumberId) {
    this.id = phoneNumberId
    this.queryOptions = { limit: 10, after: null }
  }

  init () {
    this.elements = {
      initialLoadingIndicator: $('#initial-loading-indicator'),
      loadMoreButton: $('#btn-load-more'),
      messages: $('#messages'),
      messagesSection: $('#messages-section'),
      noMessages: $('#no-messages')
    }

    this.elements.initialLoadingIndicator.removeClass('hidden')

    this.loadMessages()
    this.startStreaming()
    this.setUpEvents()
  }

  setUpEvents () {
    const self = this

    this.elements.loadMoreButton.on('click', () => this.loadMessages())

    $('#messages').on('click', '[data-action=call]', function () {
      const messageId = $(this).parents('.message').data('message-id')
      new PhoneCallRequestModal(messageId).launch()
    })
  }

  loadMessages () {
    this.elements.loadMoreButton.prop('disabled', true)
    this.elements.loadMoreButton.html('Loading...')

    axios
      .get(`/phone_numbers/${this.id}/messages.json`, { params: this.queryOptions })
      .then(response => {
        if (!this.queryOptions.after) {
          this.elements.initialLoadingIndicator.addClass('hidden')
        }

        this.elements.loadMoreButton.prop('disabled', false)
        this.elements.loadMoreButton.html('Load more')

        const messages = response.data

        if (messages.length) {
          this.elements.messagesSection.removeClass('hidden')
          for (const message of messages) {
            this.elements.messages.append(messageTemplate(message))
          }
          this.queryOptions.after = Math.min(...messages.map(m => m.id))

          if (messages.length < this.queryOptions.limit) {
            this.elements.loadMoreButton.addClass('hidden')
          }
        } else {
          this.elements.noMessages.removeClass('hidden')
          this.elements.loadMoreButton.addClass('hidden')
        }
      })
  }

  startStreaming () {
    App.cable.subscriptions.create({
      channel: 'PhoneNumberChannel',
      phone_number_id: this.id
    }, {
      connected: () => {
        console.log('Connected to ActiveCable channel.')
      },

      rejected: () => {
        console.log('Connection to ActiveCable rejected.')
      },

      received: data => {
        this.elements.messages.prepend(messageTemplate(data))

        if (this.queryOptions.after === null) {
          this.queryOptions.after = data.id
        }

        this.elements.messagesSection.removeClass('hidden')
        this.elements.noMessages.addClass('hidden')
      }
    })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const match = window.location.pathname.match(/^\/phone_numbers\/([0-9]+)/)
  if (match) {
    new PhoneNumberPage(match[1]).init()
  }
})
