'use strict'

import axios from 'axios'

const modalTemplate = `
  <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="call-modal-label" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="call-modal-label">Call my number</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div data-step="loading">
            Loading...
          </div>

          <div data-step="form">
            <div class="form-group">
              <select data-attribute="country" class="form-control"></select>
            </div>
            <div class="form-group">
              <input type="text" data-attribute="number" class="form-control" placeholder="Type your phone number here" />
            </div>
          </div>

          <div data-step="finished">
            You'll receive a call soon.
          </div>

          <div data-step="error">
            An error occurred while doing this call. Error information:<br><br>
            <pre><code data-bind="error-stack"></code></pre>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-success" data-step="form" data-action="call">Call me</button>
        </div>
      </div>
    </div>
  </div>
`.trim()

export default class PhoneCallRequestModal {
  constructor (messageId) {
    this.messageId = messageId
  }

  launch () {
    this.modalEl = $(modalTemplate).appendTo('body')
    this.modalEl.modal()

    this.setUpEvents()

    this.loadRequiredData()
  }

  changeStep (step) {
    this.modalEl.find('[data-step]').hide()
    this.modalEl.find(`[data-step=${step}]`).show()
  }

  setUpEvents () {
    this.modalEl.on('hidden.bs.modal', () => this.modalEl.remove())
    this.modalEl.on('click', '[data-action=call]', () => this.requestCall())
  }

  async loadRequiredData () {
    this.changeStep('loading')

    await this.loadCountries()
    await this.loadLastPhoneNumberUsed()

    this.changeStep('form')
  }

  async loadCountries () {
    const { data } = await axios.get(`/countries.json`)
    const countryField = this.modalEl.find('[data-attribute=country]')

    for (const country of data) {
      $('<option></option>')
        .text(country['name_with_calling_code'])
        .attr('value', country['iso_code'])
        .appendTo(countryField)
    }
  }

  async loadLastPhoneNumberUsed () {
    const { data } = await axios.get(`/phone_call_requests/last_phone_number`)

    if (data['phone_country_iso_code']) {
      this.modalEl.find('[data-attribute=country]').val(data['phone_country_iso_code'])
    }

    if (data['phone_number']) {
      this.modalEl.find('[data-attribute=number]').val(data['phone_number'])
    }
  }

  async requestCall () {
    this.changeStep('loading')

    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    const countryCode = this.modalEl.find('[data-attribute=country]').val()
    const phoneNumber = this.modalEl.find('[data-attribute=number]').val()

    try {
      await axios({
        method: 'POST',
        url: '/phone_call_requests',
        headers: {
          'X-CSRF-Token': csrfToken
        },
        data: {
          phone_call_request: {
            message_id: this.messageId,
            phone_country_iso_code: countryCode,
            phone_number: phoneNumber
          }
        }
      })

      this.changeStep('finished')
    } catch (err) {
      this.modalEl.find('[data-bind=error-stack]').text(err.stack)
      this.changeStep('error')
    }
  }
}
